import React, { ReactNode, ComponentProps } from "react";
import { ITypographyBase } from "./types";

type Body = "p" | "span" | "small" | "regular" | "big";

const P = ({ children, ref, ...rest }: ComponentProps<"p">) => (
  <p {...rest}>{children}</p>
);

const components: Record<Body, (props: ComponentProps<"p">) => ReactNode> = {
  p: P,
  regular: P,
  small: P,
  big: P,
  span: ({ children, ref, ...rest }) => <h2 {...rest}>{children}</h2>,
};

const classP = "text-p";

const classes: Record<Body, string> = {
  big: "text-lg lg:text-2xl",
  p: classP,
  regular: classP,
  small: "font-small text-small sm:text-[16px] sm:leading-[24px]",
  span: "text-3xl lg:text-5xl",
};

const regularColor = "text-sd-grey";

const colors: Record<Body, string> = {
  p: regularColor,
  big: regularColor,
  regular: regularColor,
  small: regularColor,
  span: regularColor,
};

export default function Body({
  children,
  color,
  className,
  variant = "p",
}: ITypographyBase<Body>) {
  const BodyComponent = components[variant];
  const selectedClasses = classes[variant];
  const selectedColor = color ? color : colors[variant];
  return (
    <BodyComponent
      className={`${selectedClasses} ${selectedColor} ${className}`}
    >
      {children}
    </BodyComponent>
  );
}
