import React from "react";
import PrimaryButton from "./PrimaryButton";
import { MEETING_LINK } from "../../../config/links";


const HireButton = () => {

  return (
    <PrimaryButton 
      href={MEETING_LINK}
      outline small 
      className="text-white border-white md:text-sd-primary border md:border-sd-primary px-5 py-1 
      rounded font-[500] w-10/12 block bottom-4 left-0 right-0 mx-auto text-center md:static"
    >
      <span>
        Hire us
      </span>
    </PrimaryButton>
  );
};


export default HireButton;
