import React from "react";
import { GITHUB_LINK, LINKEDIN_LINK } from "../../config/links";
import MeetingButton from "./buttons/MeetingButton";
import SocialMedia from "./SocialMedia";
import { Header, Body } from "./typography";

const Footer = () => {
  return (
    <div className="bg-sd-grey-light w-full flex p-5 lg:p-20">
      <footer className="w-full h-full max-w-[1920px] mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div className="">
            <div>
              <Body variant="small" className="block mt-4 mb-5">
                CONTACT US
              </Body>
              <Body
                variant="small"
                color="text-sd-primary"
                className="block mb-5 cursor-pointer underline"
              >
                <a
                  href="mailto:contact+website@sortdesk.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  contact@sortdesk.com
                </a>
              </Body>
              <Body
                variant="small"
                color="text-sd-primary"
                className="block cursor-pointer underline"
              >
                <a href="tel:+41775380161">+41 77 538 0161</a>
              </Body>
            </div>
            <div>
              <Body variant="small" className="block mt-16">
                OFFICE
              </Body>
              <Body
                variant="small"
                color="text-sd-primary"
                className="block mt-3"
              >
                Röntgenstrasse 44
              </Body>
              <Body
                variant="small"
                color="text-sd-primary"
                className="block mt-3"
              >
                CH-8005 Zurich
              </Body>
              <Body
                variant="small"
                color="text-sd-primary"
                className="block mt-3"
              >
                Switzerland
              </Body>
              <Body
                variant="small"
                color="text-sd-primary"
                className="cursor-pointer"
              >
                {/* eslint-disable max-len */}
                <a
                  href="https://www.google.com/maps/place/R%C3%B6ntgenstrasse+44,+8005+Z%C3%BCrich,+Switzerland/@47.3842031,8.5263968,16.82z/data=!4m5!3m4!1s0x47900a14797be9a9:0x87f07dee2717bd37!8m2!3d47.3844823!4d8.527182"
                  target="_blank"
                  rel="noreferrer"
                  className="mt-3 flex items-center"
                >
                  Open in Google Maps
                  <svg viewBox="-10 -18 60 56" width="30" height="30">
                    <path
                      fill="#0D47A1"
                      d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"
                    />
                  </svg>
                </a>
              </Body>
            </div>
          </div>
          <div className="w-full h-full">
            <div className="w-4/5 lg:w-full">
              {/* unfortunately cannot overwrite centering on mobile setting */}
              <Header variant="p" className="md:!text-left">
                Let's make your project a reality
              </Header>
            </div>

            <div className="mt-12">
              <MeetingButton />
            </div>
            <div>
              <Body variant="small" className="block mt-12">
                SOCIAL
              </Body>
              <div className="flex items-center gap-4">
                <SocialMedia link={GITHUB_LINK} content="GitHub" />
                <SocialMedia link={LINKEDIN_LINK} content="LinkedIn" />
              </div>
            </div>
          </div>
        </div>
        <Body variant="small" className="block mt-16">
          @{new Date().getFullYear()} -{" "}
          <a target="_blank" rel="noreferrer" href="privacy-policy">
            Privacy Policy
          </a>
          <br />
          <a
            href="https://storyset.com/business"
            target="_blank"
            rel="noreferrer"
          >
            Business illustrations by Storyset
          </a>
        </Body>
      </footer>
    </div>
  );
};

export default Footer;
