import React from "react";

const SocialMedia = ({ link, content }) => (
  <a 
    href={link} 
    target="_blank" 
    rel="noreferrer"
    className="text-small font-small cursor-pointer block mt-3 text-sd-primary">
    {content}
  </a>
);

export default SocialMedia;