import React from "react";
import PrimaryButton from "./PrimaryButton";
import { MEETING_LINK } from "../../../config/links";


const MeetingButton = (props) => {

  return (
    <PrimaryButton href={MEETING_LINK} {...props}>
      {props.children || "Schedule a meeting"}
    </PrimaryButton>
  );
};


export default MeetingButton;
