import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';
import BurgerButton from "./BurgerButton";
import HireButton from "../buttons/HireButton";
import { MdArrowForwardIos } from "react-icons/md"
import { LuExternalLink } from "react-icons/lu"

export default function MobileNavbar({ services, products }) {
  let [isOpen, setOpen] = useState(false);
  let navLinksRef = React.useRef(null);

  function hamburgerClick() {
    let drawer = navLinksRef.current.classList.toggle("open");
    if (drawer) {
      setOpen(true);
    } else {
      setTimeout(() => {
        setOpen(false);
      }, 1000);
    }
    Array.from(navLinksRef.current.children).forEach((link) => {
      link.classList.toggle("fade");
    });
  }

  const Products = () => (
    <>
      {products.map(({ title, link, external_link }, index) => {
        const content = (<>
          <MdArrowForwardIos className="text-white h-3 w-3" />
          <p>{title}</p>
        </>);
        const commonProps = { onClick: hamburgerClick, className: "flex items-center gap-4 ml-3", key: index };
        return (link ?
          <Link to={link ?? external_link} {...commonProps}>
            {content}
          </Link>
          :
          <a href={external_link} {...commonProps}>
            {content}
            <LuExternalLink className="text-white h-3 w-3" />
          </a>
        );
      })}
    </>
  );

  const Services = () => (
    <>
      {services.map(({ title, link }, index) => {
        return (
          <Link onClick={hamburgerClick} className="flex items-center gap-4 ml-3" key={index} to={link}>
            <MdArrowForwardIos
              className="text-white h-3 w-3"
            />
            <p>{title}</p>
          </Link>
        );
      })}
    </>
  );

  return (
    <div className="navbar_Sm_container flex md:hidden">
      <div className="nav_sm_container">
        <header className="mobile_navbar flex relative z-50 backdrop-blur-xl w-screen h-24 p-5 items-center justify-between">
          <Link className="block" to="/">
            <>
              {isOpen ? (
                <StaticImage alt="sortdesk logo" src="../../../images/logo_white.png" width={200} />
              ) : (
                <StaticImage alt="sortdesk logo" src="../../../images/logo.png" width={200} />
              )}
            </>
          </Link>
          <BurgerButton isOpen={isOpen} onClick={hamburgerClick} />
        </header>
        <nav className="nav_links" ref={navLinksRef}>
          <div className="flex flex-col gap-4 relative">
            <span>
              <p className="text-white w-fit">Products</p>
            </span>
            <Products />
            <Link onClick={hamburgerClick} to="/services">
              <p className="text-white w-fit">Services</p>
            </Link>
            <Services />
            <Link onClick={hamburgerClick} to="/about">
              <p className="text-white w-fit">Agency</p>
            </Link>
          </div>
          <HireButton />
        </nav>
      </div>
    </div>
  );
}
