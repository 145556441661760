import React, { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import HireButton from "../buttons/HireButton";
import DropdownMenu from "./DropdownMenu";
import DropdownMenuItem from "./DropdownMenuItem";

export default function Navbar({ services, products }) {
  const [isServicesMenuOpen, setIsServicesMenuOpen] = useState(false);
  const [isProductsMenuOpen, setIsProductsMenuOpen] = useState(false);
  const headerClasses =
    "hidden transition-[height] md:block z-50 max-w-[1920px] mx-auto w-full";
  const headerCloseMenuClasses = `${headerClasses} h-[96px]`;
  const headerOpenMenuClasses = `${headerClasses} h-[200px]`;
  return (
    <div className="backdrop-blur-xl w-screen fixed flex z-50">
      <header
        className={
          (isServicesMenuOpen | isProductsMenuOpen) ? headerOpenMenuClasses : headerCloseMenuClasses
        }
      >
        <div className="flex w-full h-24 p-5 lg:px-20 items-center justify-between">
          <Link to="/">
            <StaticImage
              alt="sortdesk logo"
              src="../../../images/logo.png"
              width={200}
            />
          </Link>
          <nav className="hidden md:flex items-center gap-4">
            <DropdownMenu
              MenuItem={DropdownMenuItem}
              name="Products"
              items={products}
              isOpen={isProductsMenuOpen}
              setIsOpen={setIsProductsMenuOpen}
            />
            <DropdownMenu
              MenuItem={DropdownMenuItem}
              name="Services"
              link="/services"
              items={services}
              isOpen={isServicesMenuOpen}
              setIsOpen={setIsServicesMenuOpen}
            />
            <Link
              className="text-sd-dark hover:font-semibold block min-w-[60px] w-[60px]"
              to="/about"
            >
              <p>Agency</p>
            </Link>
            <HireButton />
          </nav>
        </div>
      </header>
    </div>
  );
}
