import React, { ReactNode } from "react";
import Footer from "../components/common/Footer";
import { Navbar, MobileNavbar } from "../components/common/Navbar";
import CookieBanner from "../components/common/CookieBanner";
import { graphql, useStaticQuery } from "gatsby";

const Layout = ({ children }: { children: ReactNode }) => {
  const query = useStaticQuery(graphql`
    query {
      allServicesYaml {
        nodes {
          id
          title
          link: gatsbyPath(filePath: "/services/{ServicesYaml.title}")
        }
      }
      allProductsYaml {
        nodes {
          id
          title
          external_link
        }
      }
    }
  `);
  const services = query.allServicesYaml.nodes;
  const products = query.allProductsYaml.nodes;

  return (
    <div className="w-full h-full">
      <Navbar services={services} products={products} />
      <MobileNavbar services={services} products={products}/>
      {children}

      <Footer />
      <CookieBanner />
    </div>
  );
};

export default Layout;
