import React, { useEffect, useRef } from "react";

const TRANSITION_TIME = 250;

export default function BurgerButton({ isOpen, onClick }) {
  const buttonRef = useRef(null);
  useEffect(() => {
    const bars = buttonRef.current.children;

    for (let index = 0; index < Object.keys(bars).length; index++) {
      const bar = bars[index];
      if (isOpen) {
        if (index < 3) {
          setTimeout(() => {
            bar.classList.remove(`top-[0px]`);
            bar.classList.add(`opacity-0`);
            bar.classList.add(`-top-[4px]`);
          }, TRANSITION_TIME * index);
        } else {
          setTimeout(() => {
            bar.classList.remove(`opacity-0`);
            bar.classList.add(`top-0`);
            bar.classList.remove(`top-[32px]`);
          }, TRANSITION_TIME * 2);
        }
      } else {
        if (index < 3) {
          setTimeout(() => {
            bar.classList.add(`top-[${index * 9}px]`);
            bar.classList.remove(`opacity-0`);
            bar.classList.remove(`-top-[4px]`);
          }, TRANSITION_TIME * index);
        } else {
          setTimeout(() => {
            bar.classList.add(`opacity-0`);
            bar.classList.add(`top-[32px]`);
            bar.classList.remove(`top-0`);
          }, TRANSITION_TIME * 0);
        }
      }
    }
  }, [isOpen]);
  return (
    <button
      ref={buttonRef}
      onClick={onClick}
      name="menu toggle button"
      aria-label="menu toggle button"
      className="flex md:hidden relative w-[32px] h-[22px]"
    >
      <div
        className={`transition-all duration-${TRANSITION_TIME} h-[4px] w-[32px] rounded-sm bg-[#0d47a1] absolute top-[0px] left-0`}
      />
      <div
        className={`transition-all duration-${TRANSITION_TIME} h-[4px] w-[32px] rounded-sm bg-[#0d47a1] absolute top-[9px] left-0`}
      />
      <div
        className={`transition-all duration-${TRANSITION_TIME} h-[4px] w-[32px] rounded-sm bg-[#0d47a1] absolute top-[18px] left-0`}
      />
      <div
        className={`transition-all duration-${TRANSITION_TIME} h-[4px] w-[32px] rounded-sm bg-[#fff] absolute top-0 bottom-0 my-auto left-0 -rotate-45`}
      />
      <div
        className={`transition-all duration-${TRANSITION_TIME} h-[4px] w-[32px] rounded-sm bg-[#fff] absolute top-0 bottom-0 my-auto left-0 rotate-45`}
      />
    </button>
  );
}
