import React, { useState } from "react";
import { Link } from "gatsby";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { LuExternalLink } from "react-icons/lu";

export default function DropdownMenuItem({ link, external_link, title }) {
  const [hasHover, setHisHover] = useState(false);

  const linkClasses = "flex items-center gap-4 min-w-[200px] transition-all";
  const linkHoverClasses = `${linkClasses} font-semibold`;
  const arrowClasses = "transition-[scale] w-[20px] h-[20px] text-[#0d47a1]";
  const arrowHoverClasses = `${arrowClasses} scale-[1.2]`;

  const content = (<>
    <MdOutlineKeyboardArrowRight className={hasHover ? arrowHoverClasses : arrowClasses} />
    <p>{title}</p>
  </>);

  const commonProps = {
    onMouseEnter: () => setHisHover(true),
    onMouseLeave: () => setHisHover(false),
    className: hasHover ? linkHoverClasses : linkClasses
  };
  return (link ?
    <Link to={link} {...commonProps} > {content}</Link >
    :
    <a href={external_link} {...commonProps}>
      {content}
      <LuExternalLink className="text-black h-3 w-3" />
    </a>
  );
}
