import React, { ReactNode, ComponentProps } from "react";
import { ITypographyBase } from "./types";

type Header = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p";

const headers: Record<Header, (props: ComponentProps<"header">) => ReactNode> =
  {
    h1: ({ children, ref, ...rest }) => <h1 {...rest}>{children}</h1>,
    h2: ({ children, ref, ...rest }) => <h2 {...rest}>{children}</h2>,
    h3: ({ children, ref, ...rest }) => <h3 {...rest}>{children}</h3>,
    h4: ({ children, ref, ...rest }) => <h4 {...rest}>{children}</h4>,
    h5: ({ children, ref, ...rest }) => <h5 {...rest}>{children}</h5>,
    h6: ({ children, ref, ...rest }) => <h6 {...rest}>{children}</h6>,
    p: ({ children, ref, ...rest }) => <p {...rest}>{children}</p>,
  };

const classes = {
  h1: "text-3xl lg:text-5xl",
  h2: "text-3xl lg:text-5xl",
  h3: "text-lg lg:text-2xl",
  h4: "text-lg lg:text-2xl",
  h5: "sm:text-[18px] sm:leading-[26px]",
  h6: "sm:text-[18px] sm:leading-[26px]",
  p: "sm:text-[18px] sm:leading-[26px]",
};

const colors = {
  h1: "text-sd-black",
  h2: "text-sd-black",
  h3: "text-sd-grey",
  h4: "text-sd-grey",
  h5: "text-sd-dark",
  h6: "text-sd-dark",
  p: "text-sd-dark",
};

export default function Header({
  children,
  color,
  className,
  variant = "h2",
}: ITypographyBase<Header>) {
  const HeaderComponent = headers[variant];
  const selectedClasses = classes[variant];
  const selectedColor = color ? color : colors[variant];
  return (
    <HeaderComponent
      className={`${selectedClasses} ${selectedColor} ${className}`}
    >
      {children}
    </HeaderComponent>
  );
}
