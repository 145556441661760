import React from "react";

const PrimaryButton = ({
  children,
  className,
  href,
  onClick,
  outline,
  small
}) => {

  let styles = `border rounded border-sd-primary font-[600] ${className} `;
  const normalStyles = "text-white bg-sd-primary ";
  const outlineStyles = "text-sd-primary ";

  if (outline) {styles += outlineStyles;}
  else {styles += normalStyles;}

  if (small) {styles += "px-5 py-1";}
  else {styles += "px-8 py-3";}

  return (
    href ? (
      <a className={styles} target="_blank" rel="noreferrer" href={href}>
        {children}
      </a>
    ) : (
      <button className={styles} onClick={onClick}>
        {children}
      </button>
    )
  );
};


export default PrimaryButton;
