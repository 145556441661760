import React from "react";

import CookieConsent from "react-cookie-consent";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import { useLocation } from "@reach/router";

const CookieBanner = () => {
  const location = useLocation();
  const accept = () => initializeAndTrack(location);

  return (
    <CookieConsent
      containerClasses="sticky h-[20vh] z-50 flex justify-around items-center border border-yellow-200
      bg-yellow-100 px-10 py-4 md:flex-col md:text-center md:h-[25vh]"
      declineButtonClasses="text-sd-primary py-1 mr-4"
      buttonClasses="border border-sd-primary font-[600] text-white 
      bg-sd-primary rounded px-5 py-1"
      contentClasses="md:mb-4"
      disableStyles={true}
      enableDeclineButton={true}
      cookieName="gatsby-gdpr-google-tagmanager"
      onAccept={accept}
      buttonText="Agree"
      declineButtonText="Decline"
    >
          This website uses cookies. Read more in our{" "}
      <a target="_blank" rel="noreferrer" href="/privacy-policy" className="text-sd-primary">
              Privacy Policy
      </a>.
    </CookieConsent>
  );
};

export default CookieBanner;
