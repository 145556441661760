import React from "react";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from "gatsby";

export default function DropdownMenu({ name, link, MenuItem, items = [], isOpen, setIsOpen }) {
  const menuWrapperClasses =
    "backdrop-blur-xl absolute top-8 left-0 transition-[height] px-2 z-[2] w-[300px] flex-col transition-[height] overflow-hidden flex";
  const openCSS = `${menuWrapperClasses} py-4 h-[140px]`;
  const closeCSS = `${menuWrapperClasses}  h-0`;

  const menu = items.map((item, index) => (
    <MenuItem {...item} key={index} />
  ));

  const arrowClasses = "transition-transform min-w-[20px] w-[20px] h-[20px]";
  const arrowHooverClasses = `${arrowClasses} -rotate-180`;
  const nameClasses = "flex w-[90px] hover:font-semibold items-center gap-2";

  const content = (<>
    <p className="min-w-[68px]">{name}</p>
    <MdOutlineKeyboardArrowDown
      className={isOpen ? arrowHooverClasses : arrowClasses}
    />
  </>);

  return (
    <div
      className="relative p-2 backdrop-blur-xl"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      {link ?
        <Link to={link} className={nameClasses}>
          {content}
        </Link> :
        <span className={nameClasses}>
          {content}
        </span>
      }
      <div className={isOpen ? openCSS : closeCSS}>{menu}</div>
    </div>
  );
}
